import { SAMLAuthProvider, signInWithPopup } from 'firebase/auth';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { auth } from '../../firebaseConfig';
import Monitoring from '../../utils/monitoring';
import Button from '../Button';

/**
 * ## About this component
 *
 * This component renders a Sign In button that triggeres a SAML sign in or sign up process.
 * I doesn't do aynthing except for signing in the user.
 *
 * Any actions after signing in should be handled by an
 * [onAuthStateChanged()](https://firebase.google.com/docs/reference/js/auth.auth.md#authonauthstatechanged)
 * handler.
 *
 * ## Example
 *
 * This is how an implementation could look like that triggers an action after signing in:
 *
 * ```jsx
 * import { useEffect, useState } from 'react';
 * import { onAuthStateChanged } from '../../api';
 *
 * useEffect(() => {
 *   const unsubscribeFromOnAuthStateChanged = onAuthStateChanged((authUser) => {
 *    if (authUser) {
 *        // Do something
 *     }
 *   });
 *
 *   return () => unsubscribeFromOnAuthStateChanged();
 *   // eslint-disable-next-line react-hooks/exhaustive-deps
 * }, []);
 * ```
 *
 */

const SAMLSignInButton = ({ samlProviderId }) => {
  const [t, i18n] = useTranslation();
  auth.languageCode = i18n.language;

  const handleClick = () => {
    const provider = new SAMLAuthProvider(samlProviderId);
    signInWithPopup(auth, provider).catch((error) => {
      switch (error.code) {
        case 'auth/popup-closed-by-user':
          // No need to do anything.
          break;
        default:
          Monitoring.captureException(error);
      }
    });
  };

  return (
    <Button onClick={handleClick} variant="main">
      {t('SAMLSignInButton.submit')}
    </Button>
  );
};

SAMLSignInButton.propTypes = {
  /** The SAML provider Id to be used during signing in. */
  samlProviderId: PropTypes.string.isRequired,
};

export default SAMLSignInButton;
