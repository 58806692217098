import React from 'react';

import Title from '../../components/PageTitle';
import './PrivacyPolicy.scss';

const PrivacyPolicyEn = () => {
  return (
    <div className="privacy-policy">
      <div className="privacy-policy-title-wrapper">
        <Title width="30%" text="Privacy Policy" color="yellow" />
      </div>
      <div className="privacy-policy-main-container">
        <div className="privacy-policy-main-container-text primary-text">
          Personal data is only processed on this website for technical reasons
          necessary extent raised. In no case will the collected data sold. The
          explanation below gives an overview of how fgi Fischer Group
          International GmbH, hereinafter referred to as fgi, this Protection
          guaranteed and what kind of data is collected for what purpose become.
          Data processing on this website: fgi collects and automatically saves
          to its server log files Information that the visitor's browser
          transmits to him. This are:
          <br />
          <br />
          1. Browser type/version
          <br />
          2. Operating system used
          <br />
          3. Referrer URL (the previously visited page)
          <br />
          4. Host name of the accessing computer (IP address)
          <br />
          5. Time of server request
          <br />
          <br />
          This data cannot be assigned to specific persons for fgi. One This
          data will not be merged with other data sources made, the data are
          also processed according to a statistical Evaluation deleted.
          <div className="privacy-policy-main-container-text-title primary-text-medium">
            Cookies
          </div>
          The Internet pages use so-called cookies in several places. They serve
          to make the fgi offer more user-friendly and effective and make it
          safer. Cookies are small text files that are stored on the stored on
          the visitor's computer and saved by his browser become. Most of the
          cookies used here are so-called "Session Cookies". They will be
          automatically after the end of the visit turned off. Cookies do not
          damage the computers and do not contain viruses. You can also opt out
          of to receive fgi cookies. To do this, click the following link:
          <br />
          <br /> Opt Out Cookie for this website
          <div className="privacy-policy-main-container-text-title primary-text-medium">
            right of providing information
          </div>
          `Visitors always have the right to information about the your personal
          data stored, their origin and recipients as well as the purpose of
          data processing. The request for information should be sent to the
          address given in the imprint of the website or can be addressed to the
          following addresses: e-mail, telephone, fax.
          <div className="privacy-policy-main-container-text-title primary-text-medium">
            deletion
          </div>
          The personal data stored will be deleted if the visitor to the website
          revokes the consent to storage, if their knowledge to fulfill the
          purpose pursued with the storage Purpose is no longer required or when
          their storage is off other legal reasons is inadmissible. data for
          Billing purposes and accounting purposes are handled by a Deletion
          request not affected.
          <div className="privacy-policy-main-container-text-title primary-text-medium">
            Extended data processing by &nbsp; Google (Google Inc.)
          </div>
          This website also uses Google Analytics, a Web analytics service
          provided by Google Inc. ("Google"). Google Analytics uses so-called
          "cookies", text files that are stored on the computer of the Visitors
          are stored and an analysis of the use of the Website enabled by the
          visitor. Those generated by the cookie Information about the use of
          the visitor to this website (including the visitor's IP address) are
          sent to a server of transferred to Google in the USA and stored there.
          Google will this Use information to measure the visitor's use of the
          website to evaluate in order to generate reports on the website
          activities for the compile website operator and to further with the
          Use of the website and services related to the use of the internet
          provide. Google will also provide this information where appropriate
          Transfer to third parties where required to do so by law or where
          Third parties process this data on behalf of Google. Google will be in
          Under no circumstances will the IP address of the visitor be combined
          with other Google data get in touch. The visitor can prevent the
          installation of cookies by setting the browser software accordingly
          prevent visitors. However, we would like to point out to the visitor
          that In this case, the visitor may not be able to use all functions
          can make full use of this website. You can go beyond that the
          collection of the data generated by the cookie and your use of the
          Website-related data (including your IP address) to Google and the
          Prevent processing of this data by Google by doing the under Download
          the browser plug-in available at the following link and to install.
          More information on terms of use and You can find data protection
          under the Google Analytics conditions. under the legal guidelines of
          Google. The Privacy Policy from Google can be found here. We draw your
          attention to the fact that on this Website Google Analytics was
          extended by the code "anonymizeIp". anonymous collection of IP
          addresses (so-called IP masking). guarantee. By using this website you
          agree to the visitor with the processing of the data collected about
          the visitor by Google in the manner and manner previously described
          stated purpose.
          <br />
          <br />
          Privacy Policy of Google Browser Add-on to disable Google Analytics
          <div className="privacy-policy-main-container-text-title primary-text-medium">
            Data processing by Hubspot
          </div>
          Our website uses HubSpot, software from HubSpot Inc., USA. This
          software will enter what is known as the field of inbound marketing
          and helps us, among other things, by means of statistical analyzes and
          evaluation of the logged user behavior, our marketing strategy better
          to coordinate and optimize. Cookies are used. You can prevent the
          storage of the cookies by a corresponding Prevent the setting of your
          browser software at any time or the Delete already saved cookies.
          Please note that in the Case of blocking cookies on our website
          provided services may not be used to their full extent can. For more
          information, see the Terms of Use and according to the privacy policy
          of HubSpot Inc. at: HubSpot Privacy Policy:
          http://legal.hubspot.com/de/privacy-policy HubSpot Data processing
          agreement :&nbsp; http://legal.hubspot.com/de/dpa HubSpot Terms of
          Service: https://legal.hubspot.com/terms-of-service If you do not want
          the information about your visit to the are used for the purposes
          described, you are welcome to do so for us via email at any time. Any
          information we collect are subject to this privacy policy. HubSpot is
          subject to TRUSTe's Privacy Seal and the U.S. – EU Safe Harbor
          Framework and the U.S. – Swiss Safe Harbor Framework.
          <br />
          <br />
          HubSpot
          <br />
          2nd Floor 30 North Wall Quay
          <br />
          Dublin 1, Ireland
          <br />
          Phone: +353 1 5187500.
        </div>
        <div className="privacy-policy-main-container-text primary-text">
          <div className="privacy-policy-main-container-text-title primary-text-medium">
            Social Bookmarks
          </div>
          On our website there are so-called social bookmarks (Xing and
          LinkedIn) integrated. Social bookmarks are internet bookmarks, with to
          which the users of such a service links and news reports can collect.
          These are only available as a link on our website linked to the
          relevant services. After clicking the integrated graphic will take you
          to the page of the respective provider forwarded, i.e. only then will
          user information be sent to the transferred to the respective
          provider. Information on how to deal with yours personal data when
          using this website can be found please refer to the respective data
          protection regulations of the providers.
          <div className="privacy-policy-main-container-text-title primary-text-medium">
            Social Plug-Ins
          </div>
          Our website uses social plug-ins ("plug-ins") of the social Network
          facebook.com, which is operated by Facebook Inc., 1601 p. California
          Ave, Palo Alto, CA 94304, USA ("Facebook"). The plug-ins are marked
          with a Facebook logo or the addition "Facebook Social Plug-In". We
          used the so-called 2-click solution one. When you visit our site, you
          will not receive any personal data passed on to Facebook. Only if you
          Activate the plug-in, your browser establishes a direct connection
          with the Facebook servers.
          <br />
          <br />
          The content of the plug-in is sent directly to your browser by
          Facebook transmitted and integrated into the website. Through this
          Personally identifiable information, including but not limited to your
          IP address, URL, date and time, and other browser-related Information
          transmitted to Facebook and stored in the USA, whereby according to
          information from Facebook in Germany only one anonymous IP is
          collected.
          <br />
          <br />
          If you are logged in to Facebook, Facebook can view your visit
          Associate Facebook account. When you interact with the plug-ins, for
          For example, press the "Like" button or leave a comment submit, the
          corresponding information will be sent directly by your browser
          transmitted to Facebook, stored there and dependent on your Facebook
          settings shared with your Facebook contacts. If you If you do not want
          this, please log out of your Facebook account (before pressing the
          opt-in button).
          <br />
          <br />
          Facebook saves your data as usage profiles and uses them for
          Advertising, market research and/or needs-based purposes Design of its
          website and other websites. Used here Facebook cookies and similar
          technologies to serve ads to you on and off the Facebook Services, viz
          regardless of whether you have an account on Facebook and are logged
          in. Facebook already sets cookies for this if you use the site
          www.facebook.com and forwards your data to third parties further. We
          would like to point out that we, as the provider of the pages, do not
          Knowledge of the content of the transmitted data and their use
          received facebook. You can opt out of the installation of these
          cookies prevent the corresponding setting of your Internet browser by
          You can specify there that all third-party cookies are rejected
          become; however, we would like to point out that in this case you
          possibly not all functions of this website can fully use. Purpose and
          scope of data collection and the further processing and use of the
          data by Facebook and Your rights in this regard and setting options
          for protection Please refer to the privacy policy of Facebook at
          www.facebook.com/about/privacy/, as well as specifically for Social
          plug-ins from Facebook at de-de.facebook.com/help/443483272359009/.
          <br />
          <br />
          You can change your privacy settings on Facebook in your Also change
          account settings.
          <div className="privacy-policy-main-container-text-title primary-text-medium">
            Facebook Remarketing / Retargeting
          </div>
          On our pages are remarketing tags of the social network Facebook, 1601
          South California Avenue, Palo Alto, CA 94304, USA integrated. If you
          visit our pages, about the Remarketing tags establish a direct
          connection between your browser and made on the Facebook server.
          Facebook thereby receives the Information that you have visited our
          site with your IP address. This allows Facebook to visit our site from
          your user account assign to. We can use the information obtained in
          this way for the display use of Facebook Ads. We point out that we as
          a provider the pages have no knowledge of the content of the
          transmitted data as well as receive their use by Facebook. More
          information on this can be found in the data protection declaration of
          facebook www.facebook.com/about/privacy/. If you do not collect data
          via Want Custom Audience, you can Custom Audiences here deactivate.
          <div className="privacy-policy-main-container-text-title primary-text-medium">
            Disclaimer
          </div>
          We have carefully compiled all content on our website and checked.
          Despite all care, liability or guarantee for the accuracy,
          completeness and timeliness of the data be taken over. This also
          applies to all other websites on which referenced with hyperlinks. We
          are responsible for the content of the websites, achieved as a result
          of such a connection responsible. We reserve the right to make
          updates, Changes or additions to the information provided and to make
          data.
          <div className="privacy-policy-main-container-text-title primary-text-medium">
            Data Protection Officer
          </div>
          You can reach our data protection officer at datenschutz@hr-path.com.
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicyEn;
