import PageContainer from '../../components/PageContainer';
import {
  Title,
  Headline,
  Text,
  OL,
  LinkToGooglePrivacyPolicy,
} from './components';

const PrivacyPolicyEs = () => {
  return (
    <>
      <PageContainer>
        <Title text="Privacidad de datos" />
        <Text>
          Los datos personales sólo se procesan en este sitio web por razones
          técnicas necesarias medida planteada. En ningún caso se venderán los
          datos recogidos. La explicación a continuación ofrece una visión
          general de cómo FGI Fischer Group International GmbH, en lo sucesivo
          denominado FGI, esta Protección garantizada y qué tipo de datos son
          recogidos y para qué finalidad se convierten. Tratamiento de datos en
          este sitio web: FGI recoge y guarda automáticamente en sus archivos de
          registro del servidor Información que el navegador del visitante le
          transmite. Estos son:
        </Text>
        <OL
          items={[
            'Tipo/versión de navegador',
            'Sistema operativo utilizado',
            'URL de referencia (la página visitada anteriormente)',
            'Nombre del host del ordenador que accede (dirección IP)',
            'Hora de la solicitud al servidor',
          ]}
        />
        <Text>
          Estos datos no pueden ser asignados a personas específicas para FGI.
          Estos datos no se fusionarán con otras fuentes de datos realizadas,
          los datos también se procesan de acuerdo con una evaluación
          estadística suprimida.
        </Text>
        <Headline>Cookies</Headline>
        <Text>
          Las páginas de Internet utilizan las llamadas cookies en varios
          lugares. Sirven para que la oferta de FGI sea más fácil de usar y
          eficaz y para hacerla más segura. Las cookies son pequeños archivos de
          texto que se almacenan en el ordenador del visitante y se guardan en
          su navegador. La mayoría de las cookies utilizadas aquí son las
          llamadas &quot;Cookies de sesión&quot;. Ellos serán automáticamente
          después del final de la visita apagado. Las cookies no dañan los
          ordenadores y no contienen virus. Usted También puede optar por no
          recibir cookies FGI. Para ello, haga clic en el siguiente
          <br />
          <br /> Opt Out Cookie para este sitio web enlace:
        </Text>
        <Headline>Derecho de información</Headline>
        <Text>
          Los visitantes siempre tienen derecho a recibir información sobre sus
          datos personales almacenados, su origen y destinatarios, así como la
          finalidad del tratamiento de los datos. La solicitud de información
          debe enviarse a la dirección que figura en el pie de imprenta del
          sitio web o puede dirigirse a las siguientes direcciones: e-mail,
          teléfono, fax.
        </Text>
        <Headline>Eliminación / Supresión</Headline>
        <Text>
          Los datos personales almacenados se suprimirán si el visitante del
          sitio web revoca el consentimiento para su almacenamiento, si su
          conocimiento para cumplir la finalidad o propósito perseguida con el
          almacenamiento ya no sea necesario o cuando su almacenamiento es para
          fuera de otras razones legales no contempladas. Los datos para fines
          de facturación y contabilidad fines son tratados por una solicitud de
          supresión no se ve afectada.
        </Text>
        <Headline>
          Ampliación del tratamiento de datos por Google (Google Inc.)
        </Headline>
        <Text>
          Este sitio web también utiliza Google Analytics, un servicio analítico
          de web prestado por Google Inc. (&quot;Google&quot;). Google Analytics
          utiliza las denominadas &quot;cookies&quot;, archivos de texto que se
          almacenan en el ordenador de los visitantes y permiten realizar un
          análisis del uso del sitio web por parte del visitante. Los generados
          por la cookie Información sobre el uso del visitante a este sitio web
          (incluyendo la dirección IP del visitante) se envían a un servidor de
          Google en los EE. UU. y se almacenan allí. Google usará esta
          información para medir el uso del visitante del sitio web para evaluar
          con el fin de generar informes sobre el sitio web actividades para el
          operador del sitio web, relacionado con su uso y los servicios
          relacionados con el uso de Internet proporcionan. Google también
          proporcionará esta información, en su caso, la transferencia a
          terceros cuando así lo requiera la ley o cuando terceros procesen
          estos datos en nombre de Google. Google, en ningún caso combinará la
          dirección IP del visitante con otros datos de Google. El visitante
          puede impedir la instalación de cookies configurando el software del
          navegador en consecuencia para impedirlo.
        </Text>
        <Text>
          Sin embargo, nos gustaría señalar al visitante que, en este caso, el
          visitante puede no ser capaz de utilizar todas las funciones pueden
          hacer pleno uso de este sitio web. Usted puede ir más allá de que la
          recogida de los datos generados por la cookie y su uso de los datos
          relacionados con el sitio web (incluyendo su dirección IP) a Google y
          el tratamiento preventivo de estos datos por parte de Google, haciendo
          lo siguiente Descargar el plug-in del navegador disponible en el
          siguiente enlace e instalar. Encontrará más información sobre las
          condiciones de uso y la protección de datos en las condiciones de
          Google Analytics. bajo las directrices legales de Google. La política
          de privacidad de Google se puede encontrar{' '}
          <LinkToGooglePrivacyPolicy>aquí</LinkToGooglePrivacyPolicy>. Llamamos
          su atención sobre el hecho de que en este sitio web Google Analytics
          fue ampliado por el código “anonymizeIp&quot;. recopilación anónima de
          direcciones IP (el llamado enmascaramiento de IP). garantía. Al
          utilizar este sitio web usted está de acuerdo con el visitante con el
          tratamiento de los datos recogidos sobre el visitante por Google en la
          forma y manera descrita anteriormente y con el propósito indicado.
        </Text>
        <Text>
          Política de privacidad de Google Browser Add-on para desactivar Google
          Analytics.
        </Text>
        <Headline>Procesamiento de datos por HubSpot</Headline>
        <Text>
          Nuestra página web utiliza HubSpot, un software de HubSpot Inc., EE.
          UU... Este software se adentra en lo que se conoce como el campo del
          inbound marketing y nos ayuda, entre otras cosas, mediante análisis
          estadísticos y la evaluación del comportamiento del usuario
          registrado, a coordinar y optimizar mejor nuestra estrategia de
          marketing. Se utilizan cookies. Usted puede evitar el almacenamiento
          de las cookies por un correspondiente Evitar la configuración de su
          software de navegación en cualquier momento o la eliminación de las
          cookies ya guardadas. Tenga en cuenta que en el caso de bloqueo de
          cookies en nuestro sitio web los servicios prestados no pueden ser
          utilizados en toda su extensión puede. Para obtener más información,
          consulte las Condiciones de uso y de acuerdo con la política de
          privacidad de HubSpot Inc. en: Política de privacidad de HubSpot:
          http://legal.hubspot.com/de/privacy-policy Acuerdo de procesamiento de
          datos de HubSpot : http://legal.hubspot.com/de/dpa Condiciones de
          servicio de HubSpot: https://legal.hubspot.com/terms-of-service Si no
          deseas que la información sobre tu visita se utilice para los fines
          descritos, puedes hacerlo a través de correo electrónico en cualquier
          momento. Toda la información que recopilamos está sujeta a esta
          política de privacidad. HubSpot está sujeto al Sello de Privacidad de
          TRUSTe y al Marco de Puerto Seguro EE.UU.-UE y al Marco de Puerto
          Seguro EE.UU.-Suiza.
        </Text>
        <Text>
          HubSpot 2nd Floor 30
          <br />
          North Wall Quay Dublin 1,
          <br />
          Ireland Phone: +353 1 5187500.
        </Text>
        <Headline>Marcadores sociales</Headline>
        <Text>
          En nuestro sitio web existen los llamados marcadores sociales (Xing y
          LinkedIn) integrado. Los marcadores sociales son marcadores de
          Internet, con a qué se refieren los usuarios de dicho servicio y
          boletines la información puede recopilar. Estos sólo están disponibles
          bajo forma de enlace en nuestro sitio web relacionado con los
          servicios relevantes. Después haber hecho clic en el gráfico integrado
          le llevará a la página del respectivo proveedor transmitido, es decir,
          sólo entonces la información del usuario será enviada a la persona
          transferida a proveedor respectivo. Información sobre cómo tratar el
          tuyo Los datos personales al utilizar este sitio web pueden encontrar,
          consulte las normas respectivas en materia de protección de datos de
          proveedores.
        </Text>
        <Headline>Complementos sociales</Headline>
        <Text>
          Nuestro sitio web utiliza complementos sociales
          (&quot;complementos&quot;) de la Red social facebook.com, operada por
          Facebook Inc., 1601 pág. California Ave, Palo Alto, CA 94304, Estados
          Unidos (“Facebook”). Los complementos están marcados con el logotipo
          de Facebook o con la adición &quot;Facebook complemento social&quot;.
          Usamos la llamada solución de 2 clics. Cuando visita nuestro sitio, no
          recibe ningún dato. información personal transmitida a Facebook. Sólo
          si habilita el complemento, su navegador establece una conexión
          directa con el Servidores de Facebook.
        </Text>
        <Text>
          El contenido del complemento se envía directamente a su navegador
          mediante Facebook transmitido e integrado en el sitio web. A través de
          esto información de identificación personal, que incluye, entre otros,
          límite, su dirección IP, URL, fecha y hora y otra información
          relacionada con el navegador transmitida a Facebook y almacenada en
          Estados Unidos, según información de Facebook en Alemania, solo uno Se
          recopila propiedad intelectual anónima.
        </Text>
        <Text>
          Si ha iniciado sesión en Facebook, Facebook puede ver su visita.
          Cuenta de Facebook asociada. Cuando interactúas con los complementos,
          Por ejemplo, presione el botón &quot;Me gusta&quot; o deje un enviar
          comentario, la información correspondiente será enviado directamente
          por su navegador transmitido a Facebook, ¿hay almacenados y dependen
          de la configuración de Facebook compartida con su Contactos de
          Facebook. Si no deseas esto, por favor cierre sesión en su cuenta de
          Facebook (antes de presionar el botón registro). Facebook guarda sus
          datos en forma de perfiles de uso y los utiliza con fines
          publicitarios, estudios de mercado y/o fines basados en necesita
          Diseño de su sitio web y otros sitios web. Usado aquí cookies de
          Facebook y tecnologías similares para ofrecerte anuncios dentro y
          fuera de los servicios de Facebook, es decir, que usted tiene o no una
          cuenta en Facebook y están conectados. Facebook ya define cookies para
          esto si utiliza el sitio www.facebook.com y transmite sus datos a
          terceros. Atraemos tu tenga en cuenta que nosotros, como proveedores
          de las páginas, no Conocimiento del contenido de los datos
          transmitidos y su uso. Facebook recibió. Puedes rechazar la
          instalación de estas cookies impedir la configuración correspondiente
          de su navegador de Internet mediante Allí podrá especificar que se
          rechacen todas las cookies de terceros. volverse; sin embargo, nos
          gustaría señalar que en este caso usted posiblemente no todas las
          funciones de este sitio web puedan usar. Finalidad y alcance de la
          recogida de datos y procesamiento y uso posterior de datos por parte
          de Facebook y Sus derechos a este respecto y establecer opciones de
          protección Consulte la política de privacidad de Facebook. en
          www.facebook.com/about/privacy/, así como específicamente para
          Complementos sociales de Facebook en
          dede.facebook.com/help/443483272359009/.
        </Text>
        <Text>
          Puedes cambiar tu configuración de privacidad en Facebook, cambiando
          la configuración de la cuenta.
        </Text>
        <Headline>Remarketing / Reciblaje Facebook</Headline>
        <Text>
          En nuestras páginas hay etiquetas de remarketing de la red social.
          Facebook, 1601 Avenida Sur de California, Palo Alto, CA 94304, Estados
          Unidos integrado. Si visitas nuestras páginas, sobre Tags Las
          herramientas de remarketing establecen una conexión directa entre su
          navegador y hacerlo en el servidor de Facebook. Facebook recibe así el
          Información que indica que visitó nuestro sitio con su Dirección IP.
          Esto permite a Facebook visitar nuestro sitio desde asignar su cuenta
          de usuario. Podemos usar la información así obtenida para mostrar el
          uso de Anuncios de Facebook. Llamamos su atención sobre el hecho de
          que nosotros, como proveedor de las páginas, no tenemos conocimiento
          del contenido de los datos transmitidos, así como recibir su uso por
          parte Facebook. Más información sobre esto se puede encontrar en la
          declaración de protección de datos de Facebook
          www.facebook.com/about/privacy/. Si no recopila datos vía Quieres una
          audiencia personalizada, puedes personalizarla Las audiencias aquí
          están deshabilitadas. deaktivieren.
        </Text>
        <Headline>Aviso Legal</Headline>
        <Text>
          Hemos recopilado cuidadosamente todo el contenido de nuestro sitio web
          y comprobado.
        </Text>
        <Text>
          A pesar de todo cuidado, responsabilidad o garantía, se tendrá en
          cuenta la exactitud, exhaustividad y actualidad de los datos. Esto
          también se aplica a todos los demás sitios web en los que
          referenciados por hipervínculos. Somos responsables del contenido de
          sitios web, obtenidos como resultado de dicha conexión responsable.
          Nos reservamos el derecho de realizar actualizaciones, modificaciones
          o ampliaciones a la información proporcionada y para realizar datos.
        </Text>
        <Headline>Responsable de protección de datos</Headline>
        <Text>
          Puede contactar con nuestro delegado de Protección de Datos en
          datenschutz@hr-path.com.
        </Text>
      </PageContainer>
    </>
  );
};

export default PrivacyPolicyEs;
