import PageContainer from '../../components/PageContainer';
import {
  Title,
  Headline,
  Text,
  OL,
  LinkToGooglePrivacyPolicy,
} from './components';

const PrivacyPolicyDe = () => {
  return (
    <>
      <PageContainer>
        <Title text="Datenschutz" />
        <Text>
          Personenbezogene Daten werden auf dieser Webseite nur im technisch
          notwendigen Umfang erhoben. In keinem Fall werden die erhobenen Daten
          verkauft. Die nachfolgende Erklärung gibt einen Überblick darüber, wie
          fgi Fischer Group International GmbH, nachfolgend fgi genannt, diesen
          Schutz gewährleistet und welche Art von Daten zu welchem Zweck erhoben
          werden. Datenverarbeitung auf dieser Internetseite: fgi erhebt und
          speichert automatisch auf seinen Server-Protokolldateien
          Informationen, die der Browser des Besuchers an ihn übermittelt. Dies
          sind:
        </Text>
        <OL
          items={[
            'Browsertyp/ -version',
            'verwendetes Betriebssystem',
            'Referrer-URL (die zuvor besuchte Seite)',
            'Hostname des zugreifenden Rechners (IP Adresse)',
            'Uhrzeit der Serveranfrage',
          ]}
        />
        <Text>
          Diese Daten sind für fgi nicht bestimmten Personen zuordenbar. Eine
          Zusammenführung dieser Daten mit anderen Datenquellen wird nicht
          vorgenommen, die Daten werden zudem nach einer statistischen
          Auswertung gelöscht.
        </Text>
        <Headline>Cookies</Headline>
        <Text>
          Die Internetseiten verwenden an mehreren Stellen so genannte Cookies.
          Sie dienen dazu, das Angebot von fgi nutzerfreundlicher, effektiver
          und sicherer zu machen. Cookies sind kleine Textdateien, die auf dem
          Rechner des Besuchers abgelegt und von dessen Browser gespeichert
          werden. Die meisten der hier verwendeten Cookies sind so genannte
          "Session-Cookies". Sie werden nach Ende des Besuchs automatisch
          gelöscht. Cookies richten auf den Rechnern keinen Schaden an und
          enthalten keine Viren. Sie können sich auch dagegen entschliessen, von
          fgi Cookies zu erhalten. Klicken Sie dazu folgenden Link:
          <br />
          <br /> Opt Out Cookie für diese Website
        </Text>
        <Headline>Auskunftsrecht</Headline>
        <Text>
          Besucher haben jederzeit das Recht auf Auskunft über die bezüglich
          ihrer Person gespeicherten Daten, deren Herkunft und Empfänger sowie
          den Zweck der Datenverarbeitung. Die Anfrage zur Auskunftserteilung
          ist an unsere im Impressum der Webseite angegebene Adresse zu richten
          oder kann an folgende Adressen gerichtet werden: E-Mail, Telefon, Fax.
        </Text>
        <Headline>Löschung</Headline>
        <Text>
          Die Löschung der gespeicherten personenbezogenen Daten erfolgt, wenn
          der Besucher der Website die Einwilligung zur Speicherung widerruft,
          wenn ihre Kenntnis zur Erfüllung des mit der Speicherung verfolgten
          Zwecks nicht mehr erforderlich ist oder wenn ihre Speicherung aus
          sonstigen gesetzlichen Gründen unzulässig ist. Daten für
          Abrechnungszwecke und buchhalterische Zwecke werden von einem
          Löschungsverlangen nicht berührt.
        </Text>
        <Headline>
          Erweiterte Datenverarbeitung durch Google (Google Inc.)
        </Headline>
        <Text>
          Diese Website benutzt außerdem Google Analytics, einen
          Webanalysedienst der Google Inc. („Google“). Google Analytics
          verwendet sogenannten "Cookies", Textdateien, die auf dem Computer des
          Besuchers gespeichert werden und die eine Analyse der Benutzung der
          Website durch den Besucher ermöglicht. Die durch den Cookie erzeugten
          Informationen über die Benutzung des Besuchers dieser Website
          (einschließlich IP-Adresse des Besuchers) werden an einen Server von
          Google in den USA übertragen und dort gespeichert. Google wird diese
          Informationen benutzen, um die Nutzung des Besuchers der Website
          auszuwerten, um Reports über die Websiteaktivitäten für die
          Websitebetreiber zusammenzustellen und um weitere mit der
          Websitenutzung und der Internetnutzung verbundene Dienstleistungen zu
          erbringen. Auch wird Google diese Informationen gegebenenfalls an
          Dritte übertragen, sofern dies gesetzlich vorgeschrieben oder soweit
          Dritte diese Daten im Auftrag von Google verarbeiten. Google wird in
          keinem Fall die IP-Adresse des Besuchers mit anderen Daten der Google
          in Verbindung bringen. Der Besucher kann die Installation der Cookies
          durch eine entsprechende Einstellung der Browser Software des
          Besuchers verhindern. Wir weisen den Besucher jedoch darauf hin, dass
          der Besucher in diesem Fall gegebenenfalls nicht sämtliche Funktionen
          dieser Website voll umfänglich nutzen kann. Sie können darüber hinaus
          die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der
          Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die
          Verarbeitung dieser Daten durch Google verhindern, indem Sie das unter
          dem folgenden Link verfügbare Browser-Plugin herunterladen und
          installieren. Nähere Informationen zu Nutzungsbedingungen und
          Datenschutz finden Sie unter den Google Analytics Bedingungen bzw.
          unter den rechtlichen Richtlinien von Google. Die Datenschutzerklärung
          von Google finden Sie{' '}
          <LinkToGooglePrivacyPolicy>hier</LinkToGooglePrivacyPolicy>. Wir
          weisen Sie darauf hin, dass auf dieser Website Google Analytics um den
          Code "anonymizeIp" erweitert wurde, um eine anonymisierte Erfassung
          von IP-Adressen (sog. IP-Masking) zu gewährleisten. Durch die Nutzung
          dieser Website erklärt sich der Besucher mit der Bearbeitung der über
          den Besucher erhobenen Daten durch Google in der zuvor beschriebenen
          Art und Weise und zu dem zuvor benannten Zweck einverstanden.
        </Text>
        <Headline>Datenverarbeitung durch Hubspot</Headline>
        <Text>
          Unsere Website benutzt HubSpot, eine Software der HubSpot Inc., USA.
          Diese Software wird im so genannten Bereich des Inbound-Marketings ein
          und hilft uns u.a. mittels statistischer Analysen und Auswertung des
          protokollierten Nutzerverhaltens, unsere Marketing-Strategie besser
          koordinieren und optimieren zu können. Es werden Cookies eingesetzt.
          Sie können die Speicherung der Cookies durch eine entsprechende
          Einstellung Ihrer Browser-Software jederzeit verhindern bzw. die
          bereits gespeicherten Cookies löschen. Bitte beachten Sie, dass Sie im
          Falle der Sperrung von Cookies die auf unserer Website
          bereitgestellten Services möglicherweise nicht vollumfänglich nutzen
          können. Nähere Informationen finden Sie in den Nutzungsbedingungen und
          den Datenschutzrichtlinien der HubSpot Inc. entsprechend unter:
          HubSpot Datenschutzerklärung:
          http://legal.hubspot.com/de/privacy-policy HubSpot
          Datenverarbeitungsvereinbarung :&nbsp; http://legal.hubspot.com/de/dpa
          HubSpot AGB: https://legal.hubspot.com/terms-of-service Falls Sie
          nicht wünschen, dass die Informationen über Ihren Besuch zu den
          beschriebenen Zwecken genutzt werden, können Sie uns dies auch gern
          per E-Mail jederzeit mitteilen. Alle von uns erfassten Informationen
          unterliegen dieser Datenschutzbestimmung. HubSpot unterliegt dem
          TRUSTe ’s Privacy Seal und dem U.S. – EU Safe Harbor Framework und dem
          U.S. – Swiss Safe Harbor Framework.
          <br />
          <br />
          HubSpot
          <br />
          2nd Floor 30 North Wall Quay
          <br />
          Dublin 1, Ireland
          <br />
          Telefon: +353 1 5187500.
        </Text>
        <Headline>Social Bookmarks</Headline>
        <Text>
          Auf unserer Webseite sind so genannte Social Bookmarks (Xing und
          LinkedIn) integriert. Social Bookmarks sind Internet-Lesezeichen, mit
          denen die Nutzer eines solchen Dienstes Links und Nachrichtenmeldungen
          sammeln können. Diese sind auf unserer Webseite lediglich als Link zu
          den entsprechenden Diensten eingebunden. Nach dem Anklicken der
          eingebundenen Grafik werden Sie auf die Seite des jeweiligen Anbieters
          weitergeleitet, d.h. erst dann werden Nutzerinformationen an den
          jeweiligen Anbieter übertragen. Informationen zum Umgang mit Ihren
          personenbezogenen Daten bei Nutzung dieser Webseiten entnehmen Sie
          bitte den jeweiligen Datenschutzbestimmungen der Anbieter.
        </Text>
        <Headline>Social Plug-Ins</Headline>
        <Text>
          Unsere Webseite verwendet Social Plug-Ins („Plug-Ins“) des sozialen
          Netzwerkes facebook.com, welches von der Facebook Inc., 1601 S.
          California Ave, Palo Alto, CA 94304, USA betrieben wird („Facebook“).
          Die Plug-Ins sind mit einem Facebook Logo oder dem Zusatz „Facebook
          Social Plug-In“ gekennzeichnet. Wir setzten dabei die sog. 2-Klick-
          Lösung ein. Wenn Sie unsere Seite besuchen werden grundsätzlich keine
          personenbezogenen Daten an Facebook weitergegeben. Nur wenn Sie den
          Plug-In aktivieren, baut Ihr Browser eine direkte Verbindung mit den
          Servern von Facebook auf.
        </Text>
        <Text>
          Der Inhalt des Plug-Ins wird von Facebook direkt an Ihren Browser
          übermittelt und von diesem in die Webseite eingebunden. Hierdurch
          werden personenbasierte Information, wie unter Anderem Ihre
          IP-Adresse, URL, Datum und Uhrzeit sowie andere browserbezogene
          Informationen an Facebook übermittelt und in den USA gespeichert,
          wobei nach Angaben von Facebook in Deutschland nur eine anonymisierte
          IP erhoben wird.
        </Text>
        <Text>
          Sind Sie bei Facebook eingeloggt kann Facebook den Besuch Ihrem
          Facebook-Konto zuordnen. Wenn Sie mit den Plug-Ins interagieren, zum
          Beispiel den „Gefällt mir“ Button betätigen oder einen Kommentar
          abgeben, wird die entsprechende Information von Ihrem Browser direkt
          an Facebook übermittelt, dort gespeichert und abhängig von Ihren
          Facebook-Einstellungen Ihren Facebook-Kontakten mitgeteilt. Wenn Sie
          dies nicht wünschen, loggen Sie sich bitte aus Ihrem Facebook-Konto
          (vor Betätigung des Opt-In-Buttons) aus.
        </Text>
        <Text>
          Facebook speichert Ihre Daten als Nutzungsprofile und nutzt diese für
          Zwecke der Werbung, Marktforschung und/oder bedarfsgerechten
          Gestaltung seiner Webseite und anderer Webseiten. Hierbei verwendet
          Facebook Cookies und ähnliche Technologien, um Ihnen Werbeanzeigen auf
          den Facebook-Diensten sowie außerhalb dieser anzuzeigen, und zwar
          unabhängig davon, ob Sie bei Facebook einen Account haben und
          eingeloggt sind. Facebook setzt hierfür bereits Cookies, wenn Sie die
          Seite www.facebook.com besucht haben und leitet Ihre Daten an Dritte
          weiter. Wir weisen darauf hin, dass wir als Anbieter der Seiten keine
          Kenntnis vom Inhalt der übermittelten Daten sowie deren Nutzung durch
          Facebook erhalten. Sie können die Installation dieser Cookies durch
          die entsprechende Einstellung Ihres Internetbrowsers verhindern, indem
          Sie dort festlegen, dass alle Cookies von Drittanbietern abgewiesen
          werden; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall
          gegebenenfalls nicht sämtliche Funktionen dieser Webseite
          vollumfänglich nutzen können. Zweck und Umfang der Datenerhebung und
          die weitere Verarbeitung und Nutzung der Daten durch Facebook sowie
          Ihre diesbezüglichen Rechte und Einstellungsmöglichkeiten zum Schutz
          Ihrer Privatsphäre entnehmen Sie bitte den Datenschutzhinweisen von
          Facebook unter www.facebook.com/about/privacy/, sowie speziell für
          Social Plug-Ins von Facebook unter
          de-de.facebook.com/help/443483272359009/.
        </Text>
        <Text>
          Ihre Datenschutzeinstellungen bei Facebook können Sie in Ihren
          Konto-Einstellungen zusätzlich ändern.
        </Text>
        <Headline>Facebook Remarketing / Retargeting</Headline>
        <Text>
          Auf unseren Seiten sind Remarketing-Tags des sozialen Netzwerks
          Facebook, 1601 South California Avenue, Palo Alto, CA 94304, USA
          integriert. Wenn Sie unsere Seiten besuchen, wird über die
          Remarketing-Tags eine direkte Verbindung zwischen Ihrem Browser und
          dem Facebook-Server hergestellt. Facebook erhält dadurch die
          Information, dass Sie mit Ihrer IP-Adresse unsere Seite besucht haben.
          Dadurch kann Facebook den Besuch unserer Seiten Ihrem Benutzerkonto
          zuordnen. Die so erhaltenen Informationen können wir für die Anzeige
          von Facebook Ads nutzen. Wir weisen darauf hin, dass wir als Anbieter
          der Seiten keine Kenntnis vom Inhalt der übermittelten Daten sowie
          deren Nutzung durch Facebook erhalten. Weitere Informationen hierzu
          finden Sie in der Datenschutzerklärung von facebook unter
          www.facebook.com/about/privacy/. Falls Sie keine Datenerfassung via
          Custom Audience wünschen, können Sie Custom Audiences hier
          deaktivieren.
        </Text>
        <Headline>Haftungshinweis</Headline>
        <Text>
          Alle Inhalte auf unserer Website haben wir sorgfältig zusammengestellt
          und geprüft. Trotz aller Sorgfalt kann eine Haftung oder Garantie für
          die Richtigkeit, Vollständigkeit und Aktualität der Daten nicht
          übernommen werden. Dies gilt auch für alle anderen Websites, auf die
          mit Hyperlinks verwiesen wird. Wir sind für den Inhalt der Websites,
          die aufgrund einer solchen Verbindung erreicht werden, nicht
          verantwortlich. Wir behalten uns das Recht vor, Aktualisierungen,
          Änderungen oder Ergänzungen an den bereitgestellten Informationen und
          Daten vorzunehmen.
        </Text>
        <Headline>Datenschutzbeauftragte</Headline>
        <Text>
          Unsere Datenschutzbeauftragte erreichen Sie unter
          datenschutz@hr-path.com.
        </Text>
      </PageContainer>
    </>
  );
};

export default PrivacyPolicyDe;
