import React from 'react';

import Title from '../../components/PageTitle';
import './PrivacyPolicy.scss';

const PrivacyPolicyFr = () => {
  return (
    <div className="privacy-policy">
      <div className="privacy-policy-title-wrapper">
        <Title width="30%" text="Protection des données" color="yellow" />
      </div>
      <div className="privacy-policy-main-container">
        <div className="privacy-policy-main-container-text primary-text">
          Les données personnelles ne sont traitées sur ce site que pour des
          raisons techniques mesure nécessaire relevée. En aucun cas les données
          collectées vendu. L'explication ci-dessous donne un aperçu de la façon
          dont fgi Fischer Group International GmbH, ci-après dénommée fgi, ce
          Protection garantie et quel type de données sont collectées dans quel
          but devenir. Traitement des données sur ce site : fgi collecte et
          enregistre automatiquement dans ses fichiers journaux de serveur
          Informations que le navigateur du visiteur lui transmet. Ce sont:
          <br />
          <br />
          1. Type/version du navigateur
          <br />
          2. Système d'exploitation utilisé
          <br />
          3. URL de référence (la page visitée précédemment)
          <br />
          4. Nom d'hôte de l'ordinateur accédant (adresse IP)
          <br />
          5. Heure de la demande du serveur
          <br />
          <br />
          Ces données ne peuvent pas être attribuées à des personnes spécifiques
          pour fgi. Un Ces données ne seront pas fusionnées avec d'autres
          sources de données faites, les données sont également traitées selon
          une base statistique Évaluation supprimée.
          <div className="privacy-policy-main-container-text-title primary-text-medium">
            Biscuits
          </div>
          Les pages Internet utilisent des soi-disant cookies à plusieurs
          endroits. Ils servent à rendre l'offre fgi plus conviviale et efficace
          et le rendre plus sûr. Les cookies sont de petits fichiers texte qui
          sont stockés sur le stockées sur l'ordinateur du visiteur et
          enregistrées par son navigateur devenir. La plupart des cookies
          utilisés ici sont appelés « Cookies de session ». Ils le seront
          automatiquement après la fin de la visite éteindre. Les cookies
          n'endommagent pas les ordinateurs et ne contiennent pas de virus. Vous
          pouvez également désactiver pour recevoir les cookies fgi. Pour cela,
          cliquez sur le lien suivant :
          <br />
          <br /> Cookie de désactivation pour ce site Web
          <div className="privacy-policy-main-container-text-title primary-text-medium">
            droit de fournir des informations
          </div>
          `Les visiteurs ont toujours le droit d'être informés sur vos données
          personnelles stockées, leur origine et leurs destinataires ainsi que
          la finalité du traitement des données. La demande d'information doit
          être envoyé à l'adresse indiquée dans les mentions légales du site ou
          peut être adressé aux adresses suivantes : e-mail, téléphone, fax.
          <div className="privacy-policy-main-container-text-title primary-text-medium">
            effacement
          </div>
          Les données personnelles stockées seront supprimées si le visiteur du
          site Web révoque le consentement au stockage, si leur connaissance
          pour remplir le but poursuivi avec le stockage Le but n'est plus
          requis ou lorsque leur stockage est désactivé d'autres motifs
          juridiques est irrecevable. données pour Les fins de facturation et
          les fins de comptabilité sont traitées par un La demande de
          suppression n'est pas affectée.
          <div className="privacy-policy-main-container-text-title primary-text-medium">
            Traitement étendu des données par &nbsp; Google (Google Inc.)
          </div>
          Ce site Web utilise également Google Analytics, un Service d'analyse
          Web fourni par Google Inc. ("Google"). Google Analytics utilise des
          "cookies", des fichiers texte qui sont stockés sur l'ordinateur du Les
          visiteurs sont stockés et une analyse de l'utilisation du Site Web
          activé par le visiteur. Ceux générés par le cookie Informations sur
          l'utilisation du visiteur de ce site Web (y compris l'adresse IP du
          visiteur) sont envoyés à un serveur de transférés à Google aux
          États-Unis et y stockés. Google le fera Utiliser les informations pour
          mesurer l'utilisation du site Web par le visiteur évaluer afin de
          générer des rapports sur les activités du site Web pour le compiler
          l'opérateur du site Web et poursuivre avec le Utilisation du site Web
          et des services liés à l'utilisation d'Internet fournir. Google
          fournira également ces informations le cas échéant Transfert à des
          tiers lorsque la loi l'exige ou lorsque Des tiers traitent ces données
          pour le compte de Google. Google sera présent En aucun cas l'adresse
          IP du visiteur ne sera combinée avec d'autres données de Google entrer
          en contact. Le visiteur peut empêcher l'installation de cookies en
          configurant le logiciel de navigation en conséquence empêcher les
          visiteurs. Cependant, nous tenons à signaler au visiteur que Dans ce
          cas, le visiteur peut ne pas être en mesure d'utiliser toutes les
          fonctions peut utiliser pleinement ce site Web. Tu peux aller au-delà
          la collecte des données générées par le cookie et votre utilisation du
          Données relatives au site Web (y compris votre adresse IP) à Google et
          au Empêcher le traitement de ces données par Google en procédant comme
          indiqué ci-dessous Téléchargez le plug-in du navigateur disponible sur
          le lien suivant et à installer. Plus d'informations sur les conditions
          d'utilisation et Vous pouvez trouver la protection des données dans
          les conditions de Google Analytics. conformément aux directives
          légales de Google. La politique de confidentialité de Google peut être
          trouvé ici. Nous attirons votre attention sur le fait que sur ce Le
          site Web Google Analytics a été étendu par le code "anonymizeIp".
          collecte anonyme d'adresses IP (ce que l'on appelle le masquage IP).
          garantie. En utilisant ce site Web, vous acceptez les visiteur avec le
          traitement des données collectées sur le visiteur par Google de la
          manière décrite précédemment but déclaré.
          <br />
          <br />
          Politique de confidentialité du module complémentaire de navigateur
          Google à désactiver Google Analytics
          <div className="privacy-policy-main-container-text-title primary-text-medium">
            Traitement des données par Hubspot
          </div>
          Notre site Web utilise HubSpot, un logiciel de HubSpot Inc.,
          États-Unis. Ce logiciel entrera dans ce qu'on appelle le domaine de
          l'inbound marketing et nous aide, entre autres, au moyen d'analyses
          statistiques et d'évaluation des comportement des utilisateurs
          enregistrés, notre stratégie marketing est meilleure coordonner et
          optimiser. Des cookies sont utilisés. Vous pouvez empêcher le stockage
          des cookies par un correspondant Empêcher à tout moment le paramétrage
          de votre logiciel de navigation ou la Supprimer les cookies déjà
          enregistrés. Veuillez noter que dans le Cas de blocage des cookies sur
          notre site les services fournis ne peuvent pas être utilisés dans leur
          intégralité peut. Pour plus d'informations, consultez les conditions
          d'utilisation et conformément à la politique de confidentialité de
          HubSpot Inc. à : Politique de confidentialité HubSpot :
          http://legal.hubspot.com/de/privacy-policy HubSpot Accord de
          traitement des données :&nbsp; http://legal.hubspot.com/de/dpa
          Conditions d'utilisation de HubSpot :
          https://legal.hubspot.com/terms-of-service Si vous ne voulez pas les
          informations sur votre visite au sont utilisés aux fins décrites, vous
          êtes invités à le faire pour nous par e-mail à tout moment. Toute
          information que nous recueillons sont soumis à cette politique de
          confidentialité. HubSpot est soumis à Le sceau de confidentialité de
          TRUSTe et les États-Unis – Cadre de la sphère de sécurité de l'UE et
          États-Unis - Cadre de la sphère de sécurité suisse.
          <br />
          <br />
          HubSpot
          <br />
          2e étage 30, quai du mur nord
          <br />
          Dublin 1, Irlande
          <br />
          Téléphone : +353 1 5187500.
        </div>
        <div className="privacy-policy-main-container-text primary-text">
          <div className="privacy-policy-main-container-text-title primary-text-medium">
            Signets sociaux
          </div>
          Sur notre site Web, il y a des soi-disant signets sociaux (Xing et
          LinkedIn) intégré. Les signets sociaux sont des signets Internet, avec
          auxquels les utilisateurs d'un tel service renvoient et des bulletins
          d'information peut recueillir. Ceux-ci ne sont disponibles que sous
          forme de lien sur notre site Web liés aux services concernés. Après
          avoir cliqué sur le le graphique intégré vous amènera à la page du
          fournisseur respectif transmises, c'est-à-dire qu'alors seulement les
          informations de l'utilisateur seront envoyées au transférés au
          fournisseur respectif. Informations sur la façon de traiter le vôtre
          les données personnelles lors de l'utilisation de ce site Web peuvent
          être trouvées veuillez vous référer aux réglementations respectives en
          matière de protection des données des fournisseurs.
          <div className="privacy-policy-main-container-text-title primary-text-medium">
            Plugins sociaux
          </div>
          Notre site Web utilise des plug-ins sociaux ("plug-ins") du réseau
          social Réseau facebook.com, qui est exploité par Facebook Inc., 1601
          p. California Ave, Palo Alto, CA 94304, États-Unis ("Facebook"). Les
          plug-ins sont marqués d'un logo Facebook ou de l'ajout "Facebook
          plug-in social". Nous avons utilisé le soi-disant 2-click solution un.
          Lorsque vous visitez notre site, vous ne recevez aucune données
          personnelles transmises à Facebook. Seulement si vous Activez le
          plug-in, votre navigateur établit une connexion directe avec le
          Serveurs Facebook.
          <br />
          <br />
          Le contenu du plug-in est envoyé directement à votre navigateur par
          Facebook transmises et intégrées dans le site Web. À travers cela Les
          informations personnellement identifiables, y compris, mais sans s'y
          limiter, vos Adresse IP, URL, date et heure et autres informations
          liées au navigateur Informations transmises à Facebook et stockées aux
          États-Unis, selon les informations de Facebook en Allemagne, un seul
          anonyme La propriété intellectuelle est collectée.
          <br />
          <br />
          Si vous êtes connecté à Facebook, Facebook peut voir votre visite
          Compte Facebook associé. Lorsque vous interagissez avec les plug-ins,
          par Par exemple, appuyez sur le bouton "J'aime" ou laissez un
          commentaire soumettre, les informations correspondantes seront
          envoyées directement par votre navigateur transmis à Facebook, y sont
          stockés et dépendent de votre Paramètres Facebook partagés avec vos
          contacts Facebook. Si tu Si vous ne le souhaitez pas, veuillez vous
          déconnecter de votre compte Facebook (avant d'appuyer sur le bouton
          d'inscription).
          <br />
          <br />
          Facebook enregistre vos données sous forme de profils d'utilisation et
          les utilise pour Publicité, études de marché et/ou objectifs basés sur
          les besoins Conception de son site Web et d'autres sites Web. Utilisé
          ici Cookies Facebook et technologies similaires pour vous proposer des
          publicités sur et hors des services Facebook, à savoir que vous ayez
          ou non un compte sur Facebook et sont connectés. Facebook définit déjà
          des cookies pour cela si vous utilisez le site www.facebook.com et
          transmet vos données à des tiers plus loin. Nous attirons votre
          attention sur le fait que nous, en tant que fournisseur des pages, ne
          Connaissance du contenu des données transmises et de leur utilisation
          Facebook reçu. Vous pouvez refuser l'installation de ces cookies
          empêcher le paramétrage correspondant de votre navigateur Internet en
          Vous pouvez y spécifier que tous les cookies tiers sont rejetés
          devenir; cependant, nous tenons à souligner que dans ce cas, vous
          éventuellement pas toutes les fonctions de ce site Web peut pleinement
          utiliser. Objectif et portée de la collecte de données et le
          traitement ultérieur et l'utilisation des données par Facebook et Vos
          droits à cet égard et options de paramétrage pour la protection
          Veuillez vous référer à la politique de confidentialité de Facebook
          sur www.facebook.com/about/privacy/, ainsi que spécifiquement pour
          Plug-ins sociaux de Facebook sur
          de-de.facebook.com/help/443483272359009/.
          <br />
          <br />
          Vous pouvez modifier vos paramètres de confidentialité sur Facebook
          dans votre Modifiez également les paramètres du compte.
          <div className="privacy-policy-main-container-text-title primary-text-medium">
            Remarketing / Reciblage Facebook
          </div>
          Sur nos pages se trouvent des balises de remarketing du réseau social
          Facebook, 1601 South California Avenue, Palo Alto, CA 94304,
          États-Unis intégré. Si vous visitez nos pages, à propos de Les balises
          de remarketing établissent une connexion directe entre votre
          navigateur et faites sur le serveur Facebook. Facebook reçoit ainsi le
          Informations indiquant que vous avez visité notre site avec votre
          adresse IP. Cela permet à Facebook de visiter notre site à partir de
          votre compte utilisateur affecter à. Nous pouvons utiliser les
          informations ainsi obtenues pour l'affichage utilisation des
          publicités Facebook. Nous attirons votre attention sur le fait que
          nous, en tant que fournisseur les pages n'ont aucune connaissance du
          contenu des données transmises ainsi que recevoir leur utilisation par
          Facebook. Plus d'informations à ce sujet peut être trouvé dans la
          déclaration de protection des données de facebook
          www.facebook.com/about/privacy/. Si vous ne collectez pas de données
          via Vous voulez une audience personnalisée, vous pouvez personnaliser
          les audiences ici désactiver.
          <div className="privacy-policy-main-container-text-title primary-text-medium">
            Clause de non-responsabilité
          </div>
          Nous avons soigneusement compilé tout le contenu de notre site Web et
          vérifié. Malgré tous les soins, la responsabilité ou la garantie pour
          l'exactitude, l'exhaustivité et l'actualité des données être repris.
          Ceci s'applique également à tous les autres sites Web sur lesquels
          référencés par des hyperliens. Nous sommes responsables du contenu des
          sites Web, obtenu à la suite d'une telle connexion responsable. Nous
          nous réservons le droit de faire des mises à jour, Modifications ou
          ajouts aux informations fournies et pour faire des données.
          <div className="privacy-policy-main-container-text-title primary-text-medium">
            Délégué à la protection des données
          </div>
          Vous pouvez joindre notre délégué à la protection des données au
          datenschutz@hr-path.com.
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicyFr;
